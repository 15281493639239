<template>
<div>
    <scan-content v-show="!output" :pagemode="mode"></scan-content>
</div>
</template>

<script>
import ScanContent from "./ScanContent";
import { SCAN_PRINT_MODE, SCAN_NORMAL_MODE } from "../components/fnc.js";

export default {
  data() {
    return {
      mode: SCAN_PRINT_MODE
    };
  },
  components: {
    ScanContent
  }
};
</script>
